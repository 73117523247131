@import url("https://hyperboliq-cdd.s3.eu-west-2.amazonaws.com/all.css");

body {
    padding: 0;
    margin: 0;
  }

.basic-layout  {
    .content-area {
        background-color: #fff!important;
        border-radius: .9rem .9rem 0rem 0rem!important;
        height: calc(100% - 7rem)!important;
        min-height: calc(100vh - 7rem)!important;

        @media screen and (max-width: 1000px) {
            margin-top: 3rem;
        }
    }
    &.search-included {
        .content-area {
            margin-top: 2rem;
        }
    }
    .search {
        @media screen and (max-width: 1000px) {
            margin-top: 0;
        }
    }
}

.waiting-to-scan {
    text-align: center;
    font-size: 3rem;
}

.user-picture-img {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-img {
        height: 16rem;
        border-radius: 1rem;
        display: block;
        margin: auto;
        min-width: 6rem;
    }

    .image-container {
        position: relative;
        height: 16rem;
    }
}

.col-sm-8 .col-form-label {
    font-weight: 400;
}

.medical .btn-primary {
    color: #fff;
}

.image-icon {
    i {
      font-size: 3rem;
      justify-content: center;
      display: flex;
    }
  }

.dropdown .dropdown-menu.show {
    overflow: hidden !important;
}